@use 'sass:math';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;200;300;400;500;600;700;800;900&family=Noto+Serif+JP:wght@200;300;400;500;600;700;900&family=Zen+Old+Mincho:wght@400;500;600;700;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@mixin md {
  @media (max-width: 767px) {
    @content;
  }
}
@mixin lg {
  @media (max-width: 1023px) {
    @content;
  }
}
.scroll-bar {
  overflow-y: overlay;
  scrollbar-width: thin;
  scrollbar-color: #ffffffcc #cccccccc;
}
*::-webkit-scrollbar {
  width: 0.8rem;
  max-width: 6px;
}
*::-webkit-scrollbar-track {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  background: #efefef66;
  border-radius: 0.4rem;
}
*::-webkit-scrollbar-thumb {
  background: #cccccccc;
  border-radius: 0.4rem;
  border: 1px solid #9999;
}

body {
  overflow-x: hidden;
  overflow-y: overlay;
  @apply font-sans font-normal;
}
a {
  color: inherit;
  text-decoration: none;
  text-underline-offset: 1px;
}
p {
  padding: 0;
  margin: 0;
}
.ck.ck-reset {
  @apply w-full;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fffc;
  backdrop-filter: blur(1.5px);
  z-index: 100;
  animation: from-top 500ms ease-in forwards;
}
.mb-menu-btn {
  width: 3rem;
  height: 3rem;
  z-index: 99999;
  @apply absolute right-0 top-0;
  span {
    @apply absolute w-2/3 left-1/2 h-0.5 bg-[#cda83f] -translate-y-1/2 -translate-x-1/2 transition-all duration-500;
    &:nth-child(1) {
      top: 30%;
    }
    &:nth-child(2) {
      top: 50%;
    }
    &:nth-child(3) {
      top: 70%;
    }
  }
  &[data-open='true'] {
    span {
      background: #1d5dba;
      &:nth-child(1) {
        top: 50%;
        @apply -rotate-45;
      }
      &:nth-child(2) {
        @apply w-0;
      }
      &:nth-child(3) {
        top: 50%;
        @apply rotate-45;
      }
    }
  }
}
.menu-panel {
  z-index: 99998;
  transition: all ease-in 500ms;
  pointer-events: none;
  user-select: none;
  @apply bg-white fixed top-0 w-screen opacity-0
    left-0;
  &[data-open='true'] {
    pointer-events: all;
    @apply opacity-100;
    & > .menu-items {
      & .menu-item {
        transform: scale(1, 1) translate(0%, 0%);
        opacity: 1;
      }
    }
  }
  & > .menu-items {
    background: #fff8;
    box-shadow: 0 2px 10px rgba($color: #000000, $alpha: 0.1);
    & .menu-item {
      opacity: 0;
      transform: scale(1, 0) translate(10%, 0%);
      transform-origin: top left;
      border-bottom: 1px solid #1d5dba55;
      background: #f4f4f4;
      transition-property: all;
      transition-timing-function: ease-in-out;
      &:hover,
      &:active {
        background: #1d5dba;
        color: #fff;
      }
    }
    & a:first-child {
      & .menu-item {
        border-top: 1px solid #1d5dba55;
      }
    }
  }
}
@keyframes from-top {
  0% {
    top: -60px;
  }
  100% {
    top: 0;
  }
}
.text-shadow {
  text-shadow: 0px 0px 10px #0009, -1px -1px 1px #3f3f4e,
    -1.5px -1.5px 2px #3f3f4e;
}
.text-shadow-white {
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.6),
    -1px -1px 1px rgba(255, 255, 255, 0.6), 1px 1px 1px rgba(255, 255, 255, 0.6);
}
#home-top-slide {
  .dot {
    cursor: pointer;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    background-color: #fff;
    width: 20px;
    height: 20px;
    z-index: 99;
    box-shadow: 0 2px 4px rgba($color: #000000, $alpha: 0.2);
    &.active {
      background: #1d5dba;
    }
  }
}

.bg-news-pattern {
  background: url(../public/static/news-pattern.png) repeat;
}
.bg-work-pattern {
  background: url(../public/static/work-pattern.png) repeat;
}
.bg-recruit-pattern {
  background: url(../public/static/recruit-pattern.png) repeat;
}
.bg-clip-path {
  &--01 {
    width: 400px;
    height: 400px;
    background-image: url(../public/static/home-about.png);
    clip-path: url(#clip-path-01);
  }
}

@keyframes right {
  0%,
  100% {
    transform: translateX(0%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateX(2px);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
.hover-scale {
  transition: all 300ms;
  &:hover {
    transform: scale(var(--scale, 1.1));
  }
}
.fellow {
  max-width: 100%;
  overflow: hidden;
  position: relative;

  &:hover {
    .slides {
      animation-play-state: paused;
    }
  }
  .action-btn {
    position: absolute;
    z-index: 10;
    top: 50%;
    transform: translateY(-50%);
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border: 1px solid #fff4;
    outline: none;
    background: #fff6;
    color: #fff;
    font-size: 1.5rem;
    box-shadow: 1px 2px 10px rgba($color: #000000, $alpha: 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 300ms;
    cursor: pointer;
    &.left {
      left: 1rem;
    }
    &.right {
      right: 1rem;
    }
    &:hover {
      background: #fff;
      color: #1d5dba;
    }
  }
  .slides {
    width: max-content;
    display: flex;
    align-items: center;
    position: relative;
    transition: all 300ms;
    animation: slide-to-left linear infinite;
    &[data-move='right'] {
      img {
        animation: slide-move-right 600ms linear forwards;
      }
    }
    &[data-move='left'] {
      img {
        animation: slide-move-left 600ms linear forwards;
      }
    }
    & > div {
      height: 18rem;
      width: 18rem;
      @include md() {
        height: 8rem;
        width: 8rem;
      }
      @apply inline-flex rounded-full transition-all lg:p-4 p-2;
      & > img {
        @apply w-full h-full object-cover rounded-full overflow-hidden shadow-md lg:shadow-lg;
      }
      &:hover {
        // animation: slide-box 1.2s linear infinite;
        & > * {
          filter: brightness(1.1);
          box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.3);
        }
      }
    }
  }
}
.note-link {
  text-decoration: underline;
  font-style: italic;
  @apply self-center;
  &:hover {
    @apply text-blue-600;
  }
}

@keyframes slide-to-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
@keyframes slide-move-right {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes slide-move-left {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes slide-box {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-2deg);
  }
  50% {
    transform: rotate(0deg) scale(0.98);
  }
  75% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.heading-2 {
  @apply font-bold text-2xl py-1 relative;
  &::before {
    content: '';
    height: 3px;
    background: #1d5dba;
    @apply absolute bottom-0 left-0 w-full;
  }
  &::after {
    content: '';
    height: 3px;
    min-width: 3rem;
    background: #cda83f;
    @apply absolute bottom-0 left-0 w-1/2;
  }
  &.text-white {
    &::before {
      background: #fff;
    }
  }
}
.heading-3 {
  border-bottom: 2px dotted #cda83f;
  @apply font-bold text-xl py-2 mb-2;
}
.recruit-follow {
  @apply flex-1 justify-center items-center bg-[#FFDA1A] py-10;
  clip-path: polygon(90% 0%, 100% 50%, 90% 100%, 0% 100%, 10% 50%, 0% 0%);
  @include md() {
    @apply flex-auto h-24 py-0 pt-2;
    width: 180px;
    clip-path: polygon(50% 20%, 100% 0, 100% 80%, 50% 100%, 0 80%, 0 0);
  }
}

.bg-recruit {
  background-image: url(../public/static/recruit-top.png);
  background-repeat: no-repeat;
  background-position: center;
  @apply absolute inset-0 bg-cover;
  @include lg() {
    background-image: none;
    background: #5abfc5;
  }
}
